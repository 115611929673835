<template>
  <div>
    <list
      ref="refTypeEquipmenList"
      sort-by="tipoEquipoId"
      refetch-records-name="tipos"
      key-field="TipoEquipoId"
      :actions="actions"
      :filters.sync="filters"
      :table-columns="tableColumns"
      :table-actions="tableActions"
      :refetch-records="fetchTypeEquipments"
      :sort-dir-desc="false"
    />
  </div>
</template>

<script>
import { ref } from '@vue/composition-api'
import i18n from '@/libs/i18n'
import tiService from '@/services/informationTecnology.service'
import List from '@/components/List.vue'

export default {
  components: {
    List,
  },
  setup() {
    const refTypeEquipmenList = ref(null)
    const { fetchTypeEquipments } = tiService()
    const filters = ref([
      {
        type: 'select',
        name: 'tipoEquipoId',
        label: i18n.t('AppTI.filters.type'),
        value: '',
        options: [],
        domainName: 'tipoEquipo',
      },
    ])
    const tableColumns = [

      {
        key: 'tipoEquipoId',
        label: i18n.t('AppTI.columns.id'),
        sortable: true,
        sortKey: 'TipoEquipoId',
      },
      {
        key: 'nombre',
        label: i18n.t('AppTI.columns.name'),
        sortable: false,
      },
      {
        label: i18n.t('Lists.Actions'),
        key: 'actions',
        sortable: false,
      },
    ]
    const tableActions = ref([
      {
        name: 'edit',
        label: i18n.t('Lists.Details'),
        aclAction: 'read',
        aclResource: 'MesaAyuda',
        routeName: 'apps-type-equipment-details',
        params: ['tipoEquipoId'],
        icon: 'CodesandboxIcon',
      },
    ])
    const actions = ref([
      {
        label: i18n.t('AppTI.actions.newTypeEquipment'),
        aclAction: 'create',
        aclResource: 'MesaAyuda',
        routeName: 'apps-type-equipment-add',
        icon: 'PlusCircleIcon',
      },
    ])
    return {
      refTypeEquipmenList,
      filters,
      tableColumns,
      tableActions,
      actions,
      fetchTypeEquipments,
    }
  },
}
</script>
